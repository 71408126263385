<template>
  <div class="page-content">
    <div class="indexbox">
      <h3 style="font-size: 22px">{{month}}月考核数据驳回重提交</h3>
      <div class="tips">月考核数据提交完成时间5-8分钟</div>
      <div class="tip-line"></div>
      <div class="tips_descs">
        本考核数据是个人对本月考核情况的认知和汇总，包含考勤，日常考核，绩效考核等，部分数据与年终奖励直接关联。
        该数据真实性由部门经理审核后提交监察部确认，请如实填写。
      </div>
      <img class="imgshow" src="../../static/oa-img/tu01.jpg"/>
      <div style="display: flex; justify-content: center; margin: 20px 0 20px 0">
        <van-button native-type="submit" type="default" @click="onClick()">开始提交
        </van-button>
      </div>
      <!--      <router-link v-show="showButton" to="user">-->
      <!--        <div class="submitbtn">开始提交</div>-->
      <!--      </router-link>-->
    </div>

    <van-dialog v-model="show" :showConfirmButton="false" title="第一次提交请先绑定信息" @confirm="onConfirm">
      <!-- <img src="https://img01.yzcdn.cn/vant/apple-3.jpg" /> -->
      <van-form validate-trigger="onChange" @submit="onSubmit">
        <van-field v-model="mobile" :rules="[
            { required: true, message: '请填写手机号', trigger: 'onBlur' },
            { pattern: /^1[0-9]{10}$/, message: '手机号码格式错误！', trigger: 'onBlur' },
          ]" label="手机号" name="手机号" placeholder="" type="digit"/>
        <van-field v-model="name" :rules="[
            { required: true, message: '请输入姓名', trigger: 'onBlur' },
          ]" label="姓名" name="姓名" placeholder=""/>
        <div style="margin: 16px">
          <van-button block native-type="submit" round type="info">提交</van-button>
        </div>
      </van-form>
    </van-dialog>
  </div>
</template>

<script>
import {Row, Col, Icon, Cell, CellGroup, Button, Dialog, Form, Field} from 'vant'
import {mapMutations, mapGetters} from 'vuex'
import {getBindInfo, wxBind, getQuestionStatus} from '@/api/user'
// import wx from "weixin-js-sdk";

export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Dialog.Component.name]: Dialog.Component,
    [Form.name]: Form,
    [Field.name]: Field
  },
  computed: {
    ...mapGetters({
      wechatOpenId: 'wechatOpenId',
      userInfo: 'userInfo',
      month: 'month'
    })
  },
  data() {
    return {
      show: false,
      showButton: false,
      mobile: '', // 手机号
      name: '', // 姓名
      wxUserInfo: {} // 授权用户信息
    }
  },
  created() {
    this.getCode()
    this.getMonth()
    // Dialog.alert({
    //   message: '需重新提交，请联系人事办'
    // }).then(() => {
    //   // this.showButton = true
    //   wx.closeWindow()
    // })
  },
  mounted: function () {
  },
  methods: {
    ...mapMutations('user', ['SET_OPENID', 'SET_WXINFO', 'SET_USERINFO', 'SET_TITLE', 'SET_MONTH', 'SET_RESUBMITRECORDMONTH', 'SET_RESUBMITRECORDID']),
    onClick() {
      const month = this.getUrlParam('month')
      const reSubmitRecordId = this.getUrlParam('reSubmitRecordId')
      console.log(month)
      console.log(reSubmitRecordId)
      this.SET_MONTH(month)
      this.SET_RESUBMITRECORDID(reSubmitRecordId)

      getQuestionStatus({
        staffid: this.userInfo.id,
        month: month
      }).then((sres) => {
        console.log(month + '月问卷提交状态: ====', sres)
        if (sres.data.code == 200) {
          window.document.title = sres.data.data.month + '月考核问卷'
          // this.SET_TITLE(sres.data.data.month)
          if (sres.data.data.have == 1) {
            // 已提交
            switch (sres.data.data.auditState) {
              case -1: // 待审核
                // Dialog.alert({
                //   message: '问卷提交数据审核中'
                // }).then(() => {
                //   // on close
                // })
                this.$router.push('/stepComplete/auditing')
                break
              case 1: // 已审核
                // Dialog.alert({
                //   message: '问卷提交数据已审核'
                // }).then(() => {
                //   // on close
                // })
                this.$router.push('/stepComplete/audited')
                break
              case 2: // 已驳回
                Dialog.alert({
                  message: '需重新提交，请联系人事办'
                }).then(() => {
                  // this.showButton = true
                })
                break
              default:
                break
            }
          } else if (sres.data.data.have == 0) {
            // 未提交
            this.SET_MONTH(month)
            this.$router.push({name: 'user'})
          }
        } else {
          Dialog.alert({
            message: sres.data.msg
          }).then(() => {
            // on close
          })
        }
      })
    },
    getMonth() {
      const month = this.getUrlParam('month')
      const reSubmitRecordId = this.getUrlParam('reSubmitRecordId')
      console.log(month)
      console.log(reSubmitRecordId)
      this.SET_MONTH(month)
      this.SET_RESUBMITRECORDID(reSubmitRecordId)
      window.document.title = month + '月考核数据驳回重提交'
    },
    getCode() {
      const code = this.getUrlParam('code') // 截取路径中的code，如果没有就去微信授权，如果已经获取到了就直接传code给后台获取openId
      const local = window.location.href
      if (code == null || code === '') {
        // console.log(window.location.href)
        this.$axios
            .get('/weixin-mp/web-page/oauth', {
              params: {
                redirectUrl: local
              }
            })
            .then((res) => {
              console.log(res)
              window.location.href = res.data.data.authUrl || res.data.authUrl
            })
            .catch((err) => {
              console.log(err)
            })
      } else {
        this.getOpenId(local, code)
        this.code = code
      }
    },
    getUrlParam: function (name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      var r = window.location.search.substr(1).match(reg)
      if (r != null) return unescape(r[2])
      return null
    },
    onSubmit() {
      console.log('onSubmit', this.wxUserInfo)
      wxBind({
        name: this.name,
        phone: this.mobile,
        staffExtend: {
          openid: this.wxUserInfo.openid,
          wechatHeadimgUrl: this.wxUserInfo.headImgUrl
        }
      }).then((res) => {
        console.log(res)
        if (res.data.code == 200) {
          // this.SET_WXINFO(res.data.data.staffExtend)
          // this.SET_USERINFO(res.data.data.staffs)
          console.log('绑定成功')

          Dialog.alert({
            message: '绑定成功'
          }).then(() => {
            // on close
          })

          if (!res.data.data.assessment.id || !res.data.data.staffs.superiorId) {
            Dialog.alert({
              showConfirmButton: false,
              message: res.data.msg
            }).then(() => {
              // on close
            })
            return
          }

          let tmp_staffs = {
            ...res.data.data.staffs,
            assessment: res.data.data.assessment,
            ljJobsList: res.data.data.ljJobsList,
            jobName: res.data.data.jobname
          }
          this.SET_WXINFO(res.data.data.staffExtend)
          this.SET_USERINFO(tmp_staffs)
          this.show = false
          this.showButton = true
          // this.$router.push({ name: 'user' })
        } else {
          Dialog.alert({
            showConfirmButton: false,
            message: res.data.msg
          }).then(() => {
            // on close
          })
        }
      })
    },
    onConfirm() {
      console.log('onConfirm')
    },
    getOpenId(redirectUrl, code) {
      this.$axios
          .get('/weixin-mp/web-page/oauth', {
            params: {
              redirectUrl: redirectUrl,
              code: code
            }
          })
          .then((res) => {
            console.log(res)
            const data = res.data
            if (data.code === 200) {
              this.SET_OPENID(data.data.userInfo.openid)
              this.wxUserInfo = data.data.userInfo // 用户信息
              // 查询员工微信绑定信息
              getBindInfo({
                openid: this.wxUserInfo.openid
              }).then((cres) => {
                console.log(cres)
                if (cres.data.code == 9002) {
                  console.log('未绑定')
                  this.show = true
                } else if (cres.data.code == 200) {
                  console.log('已绑定')

                  if (!cres.data.data.assessment.id || !cres.data.data.staffs.superiorId) {
                    Dialog.alert({
                      showConfirmButton: false,
                      message: cres.data.msg
                    }).then(() => {
                      // on close
                    })
                    return
                  }

                  let tmp_staffs = {
                    ...cres.data.data.staffs,
                    assessment: cres.data.data.assessment,
                    ljJobsList: cres.data.data.ljJobsList,
                    jobName: cres.data.data.jobname
                  }
                  this.SET_WXINFO(cres.data.data.staffExtend)
                  this.SET_USERINFO(tmp_staffs)
                  // console.log(tmp_staffs)

                  // 查询上月问卷提交状态
                  // getQuestionStatus({
                  //   staffid: cres.data.data.staffs.id,
                  //   month: this.month
                  // }).then((sres) => {
                  //   console.log('上月问卷提交状态: ====', sres)
                  //   if (sres.data.code == 200) {
                  //     window.document.title = sres.data.data.month + '月考核问卷'
                  //     // this.SET_TITLE(sres.data.data.month)
                  //     if (sres.data.data.have == 1) {
                  //       // 已提交
                  //       switch (sres.data.data.auditState) {
                  //         case -1: // 待审核
                  //           // Dialog.alert({
                  //           //   message: '问卷提交数据审核中'
                  //           // }).then(() => {
                  //           //   // on close
                  //           // })
                  //           this.$router.push('/stepComplete/auditing')
                  //           break
                  //         case 1: // 已审核
                  //           // Dialog.alert({
                  //           //   message: '问卷提交数据已审核'
                  //           // }).then(() => {
                  //           //   // on close
                  //           // })
                  //           this.$router.push('/stepComplete/audited')
                  //           break
                  //         case 2: // 已驳回
                  //           Dialog.alert({
                  //             message: '问卷提交被驳回，请重新提交'
                  //           }).then(() => {
                  //             this.showButton = true
                  //           })
                  //           break
                  //         default:
                  //           break
                  //       }
                  //     } else if (sres.data.data.have == 0) {
                  //       // 未提交
                  //       this.showButton = true
                  //     }
                  //   } else {
                  //     Dialog.alert({
                  //       message: sres.data.msg
                  //     }).then(() => {
                  //       // on close
                  //     })
                  //   }
                  // })
                } else if (cres.data.code == 300) {
                  console.log('未绑定考核方案')
                  Dialog.alert({
                    showConfirmButton: false,
                    message: cres.data.msg
                  }).then(() => {
                    // on close
                  })
                }
              })
            }
            // console.log('openid: ' + this.wechatOpenId);
          })
          .catch((err) => {
            console.log(err)
            window.location.href = '/' // 刷新页面，重新授权
          })
    }
  }
}
</script>

<style scoped src="../../static/oa-img/css/index.css"></style>

<style lang="less" scoped>
</style>
